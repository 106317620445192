import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/support/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Img from "gatsby-image";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerCloud from "../../../images/services/support/banner-cloud.png";
import BannerMonitoring from "../../../images/services/support/banner-monitoring.jpg";
import BannerLongterm from "../../../images/services/support/banner-longterm.jpg";
import BannerAsset from "../../../images/services/support/banner-asset.jpg";
import BannerQa from "../../../images/services/support/banner-qa.jpg";

//Process
import OurProcess from "../../../components/services/OurProcess";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";
import ConsultationBanner from "../../../images/consultation.jpg";

const SupportMaintenance = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="IT Managed Services"
        title="Support & Maintenance"
        description="Create capacity and efficiency in your business with our range of IT Managed Services including Cloud Support, Application Monitoring & Maintenance, Software Asset Management, Quality Assurance and compliance. Get in touch for more information. "
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Support &amp; Maintenance</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="Support &amp; Maintenance"
        subtitle="Create capacity and efficiency in your busines"
        image={Banner}
      />
      {/* <ContrastSection
        title="Create capacity and efficiency in your business"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerCloud} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Cloud Support Services</h2>
            <p>
              <strong>Reduce IT costs and enhance agility</strong>
              <br/>
              We provide technical assistance, maintenance, and optimisation strategies for cloud-based systems, ensuring efficiency, security, and uninterrupted operation. We can also help with operational agility, disaster recovery, sustainability and security enhancements.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerMonitoring} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Application Monitoring &amp; Maintenance</h2>
            <p>
              <strong>Prevent downtime, reduce costs and maintain security. </strong>
              <br/>
              We keep your systems up running as they should and proactively identify and address any potential issues that arise.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerLongterm} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Long-term Support</h2>
            <p>
              <strong>A great product is always improving. </strong>
              <br/>
              That's why we offer ongoing support to ensure you can continue to build your user base and have a clear roadmap for your next product iteration.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerAsset} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Software Asset Management &amp; Reselling</h2>
            <p>
              <strong>Brokerage services & software asset management. </strong>
              <br/>
              We can provide brokerage services for licensed software and manage those assets on your behalf. We'll take care of maintenance, We'll take care of maintenance, subscriptions and support for you, reducing development and maintenance costs. When needed, we can also analyse your infrastructure, plan integrations and development and implement for you.
            </p>
          </div>
        </div>
        {/* <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={productinnovation} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-1">Infrastructure &amp; Utilities Software</h2>
            <p>
              <strong>Ensure system stability and enhances efficiency. </strong>
              <br/>
              We offer continuous surveillance and upkeep of IT systems and applications to ensure optimal performance, security, and reliability. We can provide this service to database applications, integrations, hardware, networks and system processes.
            </p>
          </div>
        </div> */}
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerQa} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Quality Assurance &amp; Compliance</h2>
            <p>
              <strong>Mitigate risk and ensure adherence to industry standards & regulations. </strong>
              <br/>
              Enhance reliability, improve operational efficiency and ensure supplier quality. We monitor planned and systematic activities to fulfil quality requirements for a system, product, program, or service including assured compliance.
            </p>
          </div>
        </div>
        <div className="getting-touch"><GetInTouchbtn textdce={'Free consultation'} /></div>
      </div>
      <Container>
        <OurProcess />
      </Container>
      {/* <Container>
      <div className="row py-6">
        <div className="col-12">
          <h1 className="title-1">Case Studies</h1>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Building Embeddable Apps with Web Components
                  <img className="plus" src={plus} />
                  <img className="minus" src={minus} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="career card">
                  <div className="card-body">
                    <div className="row mt-5 justify-content-center">
                      <div className="col-12 col-lg-6 flex-column">
                        <div>
                          A client requested we build some features into
                          their product. The catch was these had to be made
                          available on websites belonging to their own
                          external customers. We faced two challenges.
                          <ol>
                            <li>
                              We didn't have any access or control to the
                              technologies on those external websites, and
                            </li>
                            <li>
                              A rich user experience was still expected for
                              the target features.
                            </li>
                          </ol>
                          The solution? Web Components
                        </div>
                        <a href="#" class="btn btn-primary btn-icon-button mt-3">
                          <span className="btn-text">Learn more</span>
                          <span className="btn-icon">
                            <SvgIconsSet.SideArrow />
                          </span>
                        </a>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img alt="product innovation" className="img-fluid" src={productinnovation} />
                       
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Testing Narrowband IoT Deployments
                  <img className="plus" src={plus} />
                  <img className="minus" src={minus} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="career card">
                  <div className="card-body">
                    <p>
                      iTelaSoft has been working with a leading Australian
                      Telco to test Narrowband IoT deployments. The project
                      involves testing the network coverage and performance
                      of Narrowband IoT devices in various locations across
                      Australia. The project also involves testing the
                      performance of Narrowband IoT devices in various use
                      cases such as smart metering, asset tracking, and
                      environmental monitoring.
                    </p>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Creating Seamless Tracking from Client Interface to
                  Business Interface
                  <img className="plus" src={plus} />
                  <img className="minus" src={minus} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="career card">
                  <div className="card-body">
                    <p>
                      iTelaSoft has been working with a leading Australian
                      Telco to test Narrowband IoT deployments. The project
                      involves testing the network coverage and performance
                      of Narrowband IoT devices in various locations across
                      Australia. The project also involves testing the
                      performance of Narrowband IoT devices in various use
                      cases such as smart metering, asset tracking, and
                      environmental monitoring.
                    </p>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      </Container> */}
      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "managed-services-1024x528.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default SupportMaintenance;